import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Tooltip, DatePicker, Pagination } from 'antd';
import { useTranslation } from 'react-i18next';
import GlobalContext from 'context/GlobalContext';

const OrderList = ({
  filteredData,
  onSearch,
  onDateChange,
  onSelect,
  orderDateRef,
  orderSearchRef,
  dateRangeValue
}) => {
  const { t } = useTranslation();
  const globalContext = useContext(GlobalContext);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { RangePicker } = DatePicker;

  useEffect(() => {
    setCurrentPage(1);
  }, [globalContext?.selectedSpecialOrderCustomer]);

  const handleSelectOrder = order => {
    setSelectedOrderId(`${order.orderId}-${order.customerNumber.trim()}`);
    onSelect(order);
  };

  const handlePageChange = (page, newPageSize) => {
    setCurrentPage(page);
    setPageSize(newPageSize);
  };

  const handleSearch = event => {
    onSearch(event);
    setCurrentPage(1);
  };

  const handleDateChange = dates => {
    onDateChange(dates);
    setCurrentPage(1);
  };

  const paginatedData = filteredData?.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <div className="special-order-lsit-wrapper" data-testid="special-order-list-wrapper">
      <div className="item-content-wrapper">
        <div className="item-content">
          <Row className="row">
            <Col span={24}>
              <div className="s-order-search-wrap">
                <Row>
                  <input
                    ref={orderSearchRef}
                    placeholder={t('specialOrders.orderId')}
                    type="search"
                    className="s-order-search"
                    id="order-search"
                    onChange={handleSearch}
                    data-testid="s-order-search"
                  />
                  <span className="ant-input-suffix s-order-search-icon">
                    <i aria-label="icon: search" tabIndex="-1" className="anticon anticon-search ant-input-search-icon">
                      <svg
                        viewBox="64 64 896 896"
                        focusable="false"
                        className=""
                        data-icon="search"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0 0 11.6 0l43.6-43.5a8.2 8.2 0 0 0 0-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path>
                      </svg>
                    </i>
                  </span>
                </Row>
              </div>
              <div>
                <Row>
                  <div className="s-order-date-col">
                    <RangePicker
                      ref={orderDateRef}
                      id="order-date"
                      value={dateRangeValue}
                      className="s-order-date"
                      format="YYYY-MM-DD"
                      placeholder={[t('specialOrders.orderList.startDate'), t('specialOrders.orderList.endDate')]}
                      onChange={handleDateChange}
                      showToday={false}
                      allowEmpty={[true, true]}
                    />
                  </div>
                </Row>
              </div>
              <div className="s-order-item-list-wraper">
                {paginatedData?.map(order => (
                  <div key={`${order.orderId}-${order.customerNumber.trim()}`}>
                    <Row
                      className={`s-order-item-list-col ${
                        selectedOrderId === `${order.orderId}-${order.customerNumber.trim()}` ? 'selected-item' : ''
                      }`}
                      onClick={() => handleSelectOrder(order)}
                      data-testid="s-order-item-list-col"
                    >
                      <Col span={10} className="s-order-smart-description">
                        <div className="supc">
                          <span className="category">{t('specialOrders.orderList.orderNumber')}</span>
                          <span className="card-value">{order.orderId}</span>
                        </div>

                        <div className="supc">
                          <span className="category">{t('specialOrders.orderList.date')}</span>
                          <span className="card-value">{order.orderDate}</span>
                        </div>
                      </Col>
                      <Col span={14} className="s-order-smart-description list-qty">
                        <div className="supc qty">
                          <span className="category">{t('specialOrders.orderList.quantity')}</span>
                          <span className="card-value">{order.orderedQuantity}</span>
                        </div>
                        {globalContext.selectedSpecialOrderCustomer?.id === '0' && (
                          <div className="supc qty">
                            <Tooltip title={order?.customerName}>
                              <span className="category">{t('specialOrders.orderList.customerNumber')}</span>
                              <span className="card-value">{order.customerNumber.trim()}</span>
                            </Tooltip>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
          {globalContext.isCustomerFilterVisible && (
            <div className="pagination">
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={filteredData?.length}
                onChange={handlePageChange}
                showSizeChanger
                pageSizeOptions={['5', '10', '20', '50']}
                data-testid="pagination"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderList;

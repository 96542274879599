import React, { useEffect } from 'react';
import OrderPage from 'pages/orderPage';
import styles from './styles/App.scss';

import AppLogo1 from './styles/images/app-logos/special-order.svg';

const setAppHeaderToAppMode = () => {
  if (document.getElementById('app-bar')) {
    document.getElementById('app-bar').className = 'app-bar app-mode';
  }
};

const setAppLogo = () => {
  if (document.getElementById('app-logo')) {
    document.getElementById('app-logo').src = AppLogo1;
  }
};

export default function MainAppComponent() {
  setAppHeaderToAppMode();
  setAppLogo();

  useEffect(() => {
    styles.use();
    return () => {
      styles.unuse();
    };
  }, []);

  return (
    <div className="special-order-content-wrapper">
      <OrderPage />
    </div>
  );
}

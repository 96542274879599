let authenticator = null;

export const setAuth = auth => {
  authenticator = auth;
};

export const getAuth = () => {
  return authenticator;
};

export const logout = () => {
  if (authenticator) authenticator.logout();
};

export const isLoggedIn = () => {
  if (authenticator) return authenticator.isLoggedIn();
  return true;
};

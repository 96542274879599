import React from 'react';
import { useTranslation } from 'react-i18next';

function AppLoader({ show, delayText }) {
  const { t } = useTranslation();
  return (
    <div className={show ? 'app-loader show' : 'app-loader'}>
      {delayText && (
        <div className="loader-text">
          {t('specialOrders.message.loadingText')}
          <span className="dots">
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </span>
        </div>
      )}
      <div className="boxes">
        <div className="box">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="box">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="box">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="box">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
}

export default AppLoader;

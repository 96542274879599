import React, { useEffect } from 'react';
import { GlobalContextProvider } from 'context/GlobalContext';
import * as Authenticator from 'Authenticator';
import i18n from './_i18n';
import MainApp from './MainApp';

function App({
  language,
  userDetails,
  isCustomerFilterVisible,
  setIsCustomerFilterVisible,
  selectedSpecialOrderCustomer,
  setSelectedSpecialOrderCustomer,
  setSpecialOrderCustomers,
  Auth,
  isLoggedIn
}) {
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    Authenticator.setAuth(Auth);
    return () => {
      Authenticator.setAuth(null);
    };
  }, [Auth]);

  const globalContextValue = {
    language,
    userDetails,
    isCustomerFilterVisible,
    setIsCustomerFilterVisible,
    selectedSpecialOrderCustomer,
    setSelectedSpecialOrderCustomer,
    setSpecialOrderCustomers,
    isLoggedIn
  };

  return (
    <GlobalContextProvider value={globalContextValue}>
      <MainApp />
    </GlobalContextProvider>
  );
}

export default App;

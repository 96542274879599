import moment from 'moment';
import { create } from 'zustand';

const orderStore = create(set => ({
  orderListData: [],
  selectedOrder: {},
  searchTerm: '',
  filteredData: [],
  dateRange: [null, null],

  setOrderListData: orderList =>
    set({
      orderListData: orderList,
      selectedOrder: orderList?.[0],
      filteredData: orderList
    }),

  setFilteredData: data => set({ filteredData: data }),

  setSearchTerm: term =>
    set(state => {
      const lowercasedTerm = term.toLowerCase();
      const [startDate, endDate] = state.dateRange || [null, null];

      const filteredData = state.orderListData.filter(item => {
        const matchesTerm = item.orderId.toLowerCase().includes(lowercasedTerm);
        const orderDate = moment(item.orderDate);

        const matchesDateRange =
          (!startDate || orderDate.isSameOrAfter(moment(startDate, 'YYYY-MM-DD'))) &&
          (!endDate || orderDate.isSameOrBefore(moment(endDate, 'YYYY-MM-DD')));

        return matchesTerm && matchesDateRange;
      });

      return { searchTerm: term, filteredData };
    }),

  setDateRange: dates =>
    set(state => {
      const [startDate, endDate] = dates || [null, null];
      const lowercasedTerm = state.searchTerm.toLowerCase();
      const filteredData = state.orderListData.filter(item => {
        if (!item?.orderId) return false;

        const matchesTerm = item.orderId.toLowerCase().includes(lowercasedTerm);
        const orderDate = moment(item.orderDate);

        const matchesDateRange =
          (!startDate || orderDate.isSameOrAfter(moment(startDate, 'YYYY-MM-DD'))) &&
          (!endDate || orderDate.isSameOrBefore(moment(endDate, 'YYYY-MM-DD')));

        return matchesTerm && matchesDateRange;
      });

      return { dateRange: dates, filteredData };
    }),

  setSelectedOrder: order => set({ selectedOrder: order })
}));

export default orderStore;

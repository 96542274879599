import React from 'react';

const SummeryCard = ({ title, value }) => {
  return (
    <div className="summery-card">
      <h4 className="heading">{title}</h4>
      <span className="count">{value}</span>
    </div>
  );
};

export default SummeryCard;

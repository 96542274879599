import { getRequest } from '_http';
import AppLoader from 'components/AppLoader';
import ItemsList from 'components/ItemsList';
import ItemsSummery from 'components/ItemsSummery';
import GlobalContext from 'context/GlobalContext';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import openNotification from 'components/openNotification';
import orderStore from 'store/orderStore';
import OrderList from 'components/OrderList';
import DEFAULT_CUTOMER from '../utils/constants';

const OrderPage = () => {
  const globalContext = useContext(GlobalContext);
  const [orderItems, setOrderItems] = useState([]);
  const [orderSummery, setOrderSummery] = useState([]);
  const [isOrdersLoading, setIsOrdersLoading] = useState(false);
  const [isItemsLoading, setIsItemsLoading] = useState(false);
  const [isEmptyOrderList, setIsEmptyOrderList] = useState(false);
  const [isEmptyCustomerList, setIsEmptyCustomerList] = useState(false);
  const [isGatewayTimeout, setIsGatewayTimeout] = useState(false);
  const [dateRangeValue, setDateRangeValue] = useState(null);
  const [delayText, setDelayText] = useState(false);

  const {
    selectedOrder,
    filteredData,
    setSearchTerm,
    setOrderListData,
    setFilteredData,
    setSelectedOrder,
    setDateRange
  } = orderStore();

  const { t } = useTranslation();

  const getUserId = email => {
    const atIndex = email.indexOf('@');
    return atIndex !== -1 ? email.slice(0, atIndex) : email;
  };

  const orderDateRef = useRef();
  const orderSearchRef = useRef();

  useEffect(() => {
    const getSpecialOrders = async () => {
      try {
        setIsOrdersLoading(true);
        const userId = getUserId(globalContext?.userDetails?.userDetails?.esyscoUserId);
        const { data: SpecialOrderInfo } = await getRequest(`/headers?userId=${userId}`);
        if (SpecialOrderInfo?.length > 0) {
          globalContext.setIsCustomerFilterVisible(true);

          const defaultCustomer = DEFAULT_CUTOMER;
          defaultCustomer.orders = [];
          SpecialOrderInfo.unshift(defaultCustomer);
          SpecialOrderInfo.forEach(customer => defaultCustomer.orders.push(...customer.orders));

          const selectedCustomer = SpecialOrderInfo[0];
          globalContext.setSpecialOrderCustomers(SpecialOrderInfo);
          globalContext.setSelectedSpecialOrderCustomer(selectedCustomer);
          setOrderListData(selectedCustomer.orders);
          setFilteredData(selectedCustomer.orders);
          setIsEmptyCustomerList(false);
        } else {
          setIsEmptyOrderList(true);
        }
        setIsOrdersLoading(false);
      } catch (e) {
        console.log('error: ', e);
        setIsOrdersLoading(false);
        if (e.response?.data?.code === 404) {
          setIsEmptyCustomerList(true);
        } else if (e.response?.status === 504) {
          setIsGatewayTimeout(true);
        } else if (globalContext?.isLoggedIn) {
          openNotification({
            description: t('specialOrders.errorNotification.description.orderListFetchError'),
            className: 'error',
            message: t('specialOrders.errorNotification.message.orderListFetchError')
          });
        }
      }
    };
    getSpecialOrders();
  }, [globalContext?.isLoggedIn]);

  useEffect(() => {
    if (globalContext?.selectedSpecialOrderCustomer) {
      orderSearchRef.current.value = '';
      setDateRangeValue([]);
      setOrderListData(globalContext?.selectedSpecialOrderCustomer?.orders);
      setFilteredData(globalContext?.selectedSpecialOrderCustomer?.orders);
    }
  }, [globalContext?.selectedSpecialOrderCustomer]);

  // handling the fetching of item list when the selected order changed
  useEffect(() => {
    setOrderSummery([
      {
        key: 1,
        title: `${t('specialOrders.order')}`,
        value: selectedOrder?.orderId
      },
      {
        key: 2,
        title: `${t('specialOrders.orderDate')}`,
        value: selectedOrder?.orderDate
      },
      {
        key: 3,
        title: `${t('specialOrders.quantityOrdered')}`,
        value: selectedOrder?.orderedQuantity
      },
      {
        key: 4,
        title: `${t('specialOrders.quantityShipped')}`,
        value: selectedOrder?.shippedQuantity
      },
      {
        key: 5,
        title: `${t('specialOrders.customer')}`,
        value:
          selectedOrder.customerName && selectedOrder.customerNumber && selectedOrder.customerAddress
            ? `${selectedOrder?.customerName?.trim()} - ${selectedOrder?.customerNumber?.trim()}\n
        ${selectedOrder?.customerAddress?.trim()}`
            : ''
      }
    ]);

    const getOrderItems = async () => {
      try {
        setIsItemsLoading(true);
        const { data: items } = await getRequest('/items', {
          orderNumber: selectedOrder?.orderId,
          customerId: selectedOrder?.customerNumber?.trim(),
          opcoId: selectedOrder?.siteId,
          localization: globalContext?.language?.toUpperCase()
        });
        setOrderItems(items);
        setIsItemsLoading(false);
      } catch (e) {
        console.log(e);
        setIsItemsLoading(false);
        openNotification({
          description: t('specialOrders.errorNotification.description.orderItemsFetchError'),
          className: 'error',
          message: t('specialOrders.errorNotification.message.orderItemsFetchError')
        });
      }
    };

    if (selectedOrder?.orderId) {
      getOrderItems();
    } else {
      setOrderItems([]);
    }
  }, [selectedOrder, t]);

  useEffect(() => {
    if (isOrdersLoading) {
      setTimeout(() => {
        setDelayText(true);
      }, 3000);
    }
    setDelayText(false);
  }, [isOrdersLoading]);

  const onSearch = event => {
    setSearchTerm(event.target.value);
  };

  const onDateChange = dates => {
    setDateRange(dates);
    setDateRangeValue(dates);
  };

  const onSelect = order => {
    setSelectedOrder(order);
  };

  return (
    <div className={`special-order-content ${isEmptyOrderList ? 'transparent' : ''}`}>
      {!isOrdersLoading ? (
        <>
          <div className="orderList-page">
            <OrderList
              filteredData={filteredData}
              onSearch={onSearch}
              onDateChange={onDateChange}
              onSelect={onSelect}
              orderDateRef={orderDateRef}
              orderSearchRef={orderSearchRef}
              dateRangeValue={dateRangeValue}
            />
          </div>
          <div className="order-view-page">
            <ItemsSummery summeryDetails={orderSummery} />
            <ItemsList itemdata={orderItems} isItemsLoading={isItemsLoading} />
          </div>
          {isEmptyOrderList && (
            <div className="transparent-background">
              <div className="centered-message">{t('specialOrders.message.noOrdersFound')}</div>
            </div>
          )}
          {isEmptyCustomerList && (
            <div className="transparent-background">
              <div className="centered-message">{t('specialOrders.message.noCustomersFound')}</div>
            </div>
          )}
          {isGatewayTimeout && (
            <div className="transparent-background">
              <div className="centered-message">{t('specialOrders.message.gatewayTimeout')}</div>
            </div>
          )}
        </>
      ) : (
        <AppLoader delayText={delayText} />
      )}
    </div>
  );
};

export default OrderPage;

import { Table } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getOrderStatusDict, getOrderStatusFilter } from '../utils/orderStatus';

const ItemsList = ({ itemdata, isItemsLoading }) => {
  const { t } = useTranslation();
  const filterData = getOrderStatusFilter(t);
  const orderStatusDict = getOrderStatusDict(t);

  const columns = [
    {
      title: `${t('specialOrders.supc')}`,
      dataIndex: 'id',
      defaultSortOrder: 'descend',
      render: (text, record) => (
        <a href={record.ieLink} target="_blank" rel="noopener noreferrer">
          {record.supc}
        </a>
      ),
      sorter: (a, b) => a.supc - b.supc
    },
    {
      title: `${t('specialOrders.pack')}`,
      dataIndex: 'pack',
      defaultSortOrder: 'descend'
    },
    {
      title: `${t('specialOrders.size')}`,
      dataIndex: 'size',
      defaultSortOrder: 'descend'
    },
    {
      title: `${t('specialOrders.brand')}`,
      dataIndex: 'brand',
      defaultSortOrder: 'descend'
    },
    {
      title: `${t('specialOrders.brandName')}`,
      dataIndex: 'brandName',
      defaultSortOrder: 'descend'
    },
    {
      title: `${t('specialOrders.description')}`,
      dataIndex: 'description',
      defaultSortOrder: 'descend'
    },
    {
      title: `${t('specialOrders.item.quantityOrdered')}`,
      dataIndex: 'orderedQuantity',
      defaultSortOrder: 'descend'
    },
    {
      title: `${t('specialOrders.item.quantityShip')}`,
      dataIndex: 'shippedQuantity',
      defaultSortOrder: 'descend'
    },
    {
      title: `${t('specialOrders.opcoRecievingDate')}`,
      dataIndex: 'warehouseReceivedDate',
      defaultSortOrder: 'descend'
    },
    {
      title: `${t('specialOrders.shipDate')}`,
      dataIndex: 'expectedDate',
      defaultSortOrder: 'descend'
    },
    {
      title: `${t('specialOrders.status')}`,
      dataIndex: 'orderStatus',
      defaultSortOrder: 'descend',
      filterSearch: true,
      filters: filterData,
      onFilter: (value, record) => record.orderStatus.includes(value),
      render: (text, record) => orderStatusDict[record.orderStatus]
    },
    {
      title: `${t('specialOrders.poNumber')}`,
      dataIndex: 'customerPoNumber',
      defaultSortOrder: 'descend'
    }
  ];
  return (
    <div>
      <h2 className="heading-order">{t('specialOrders.items')}</h2>
      <div className="order-items-table-wraper">
        <Table className="order-items-table" columns={columns} dataSource={itemdata} loading={isItemsLoading} />
      </div>
    </div>
  );
};

export default ItemsList;

import React from 'react';
import ReactDOM from 'react-dom';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import singleSpaReact from 'single-spa-react';
import App from './App';

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  parcelCanUpdate: true
});

export const bootstrap = [reactLifecycles.bootstrap];

export async function mount(props) {
  return reactLifecycles.mount(props);
}

export const unmount = [reactLifecycles.unmount];

export const update = [reactLifecycles.update];

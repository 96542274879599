export function getOrderStatusFilter(t) {
  const ORDER_STATUS_FILTER = [
    { text: t('specialOrders.status.item.ordered'), value: 'ITEM_ORDERED' },
    { text: t('specialOrders.status.po.created'), value: 'PO_CREATED' },
    { text: t('specialOrders.status.item.received.in.warehouse'), value: 'ITEM_RECEIVED_IN_WAREHOUSE' },
    { text: t('specialOrders.status.item.ready.to.ship'), value: 'ITEM_READY_TO_SHIP' },
    { text: t('specialOrders.status.item.shipped'), value: 'ITEM_SHIPPED' }
  ];

  return ORDER_STATUS_FILTER;
}

export function getOrderStatusDict(t) {
  return getOrderStatusFilter(t).reduce((acc, statusObj) => ({ ...acc, [statusObj.value]: statusObj.text }), {});
}
